.main-network{
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    .infoWrap{
        display: flex;
        flex-direction: column;
        line-height: 24px;
        align-items: flex-end;
        .infoAd{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;   
            color: #fff;
        }
        .infoRole{
            color: #adadad;
            font-size: 12px;
        }
        
    }
}
.mainPre-popover .flex-between-middle{
    width: 268px;
    height: 96px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .network-left-top{
        white-space: nowrap;
        display: flex;
        h4{
            display: inline-block;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            margin-right: 4px;
            max-width: 97px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 0;
            margin-bottom: 0;
            line-height: normal;
        }
        span{
            display: inline-block;
            color: #fff;
            background-color: #4697f8;
            border-radius:10px 10px 10px 0px ;
            font-weight: 400;
            height: 16px;
            padding: 0 6px;
            font-size: 12px;
            position: relative;
            top:2px;
            max-width: 106px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    span.network-left-email{
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7E848E;
        display: block;
        max-width: 207px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .network-right{
        width: 60px;
        margin-left: 12px;
    }
}
.network-popover-content{
    background: #fff;
    .mainPre-menu{
        border: none;
    }
    .mainPre-menu-item{
        .mainPre-menu-title-content{
            display: flex;
        .idName-name{
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
     }
    }
    .network-footer{
        height: 40px;
        border-top: 1px solid #eeeff0;
        text-align: center;
        line-height: 40px;
        .mainPre-btn-link{
            color: #3388FF;
        }
    }
    .network-list-ul{
        max-height: 240px;
        overflow: auto;
    }
}
.main-network-content{
    .mainPre-popover-title{
        background:url('../../assets/img/main-networkbg.png') no-repeat 100%;
    }
    .mainPre-popover-inner-content{
        padding: 0;
    }
    .mainPre-menu:not(.mainPre-menu-horizontal) .mainPre-menu-item-selected{
        background-color: #fff;
        color: #000;
    }
    .mainPre-menu-item-selected{
        position: relative;
        background-color: #fff;
        color: #000;
        .checkOutlined{
            position: absolute;
            right: 29px;
            top: 13px;
            color: #4697f8;
        }
    }
}

.m-r-xs{
    margin-right: 6px;
}
@primary-color: #3388FF;@ant-prefix: mainPre;