body{
    position: relative;
    min-height: 100vh;
    background: #f1f2f6;
}
.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  
  .main {
    width: 368px;
    margin: auto auto;
    .logo {
        width: 180px;
        margin-bottom:20px
      }
  }
  .text-right{
      text-align: right;
  }
  .w-full{
      position: relative;
      width: 100%;
  }
  
@primary-color: #3388FF;@ant-prefix: mainPre;