.main-header-con{
  height: 122px;
  .main-tab{
    background-color: #002358 !important;
    display: flex;
    align-items: center;
    .logoWrap{
      width:auto;
      height: 58px;
      display: inline-flex;
      align-items: center;
      flex-shrink: 0;
      padding: 15px 24px;
      .logoImg {
        width:auto;
        height: 32px;
      }
    }
    .tabWrap{
      flex-grow: 1;
      display: inline-flex;
      padding-right: 24px;
      height: 58px;
      .mainPre-menu-dark.mainPre-menu-horizontal{
        line-height: 58px;
        cursor: pointer;
      }
      .mainPre-menu-dark .mainPre-menu-item, .mainPre-menu-dark .mainPre-menu-item-group-title, .mainPre-menu-dark .mainPre-menu-item > a, .mainPre-menu-dark .mainPre-menu-item > span > a{
        color: #fff !important;
        font-size: 16px !important;
        min-width: 110px !important;
        text-align: center;
      }
      .mainPre-menu.mainPre-menu-dark, .mainPre-menu-dark .mainPre-menu-sub, .mainPre-menu.mainPre-menu-dark .mainPre-menu-sub{
        background-color: transparent !important;
        min-width: 400px;
      }
      .mainPre-menu-dark.mainPre-menu-horizontal > .mainPre-menu-item:hover{
        background-color: #0e4688 !important;
      }
      .mainPre-menu.mainPre-menu-dark .mainPre-menu-item-selected, .mainPre-menu-submenu-popup.mainPre-menu-dark .mainPre-menu-item-selected{
        background-color: #0e4688 !important;
      }
    }
    .networkWrap{
      position: relative;
      flex-shrink: 0;
      padding-right: 10px;
      box-sizing: border-box ;
      text-align: right;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #fff !important;
    }
  }
  .main-header{
    background-color: #f4f4f4 !important;
    border-bottom: 1px #eae7e7 solid;
    display: flex;
    // box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    padding: 0 24px 0 8px!important;
    .menuWrap{
      position: relative;
      flex-grow: 1;
      display: inline-flex;
      box-sizing: border-box;
      ul{
        position: relative;
      }
      .mainPre-menu-horizontal{
        border-bottom: none;
        flex-grow: 1;
        .mainPre-menu-submenu-selected{
          &::after{
            border: none;
          }
        }
        .mainPre-menu-item{
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          &:hover{
            color: rgba(51, 136, 255, 1);
            &::after{
              border: none;
            }
          }
          &::after{
            border: none;
          }
        }
        .mainPre-menu-item-active{
          border: none !important;
          &:hover{
            &::after{
              border: none;
            }
          }
  
        }
        .mainPre-menu-item-selected{
          position: relative;
          color: rgba(51, 136, 255, 1);
          .mainPre-menu-title-content{
            position: relative;
            display: inline-block;
            &::after{
              content: '';
              width: 30px;
              height: 2px;
              position: absolute;
              left: 50%;
              bottom:0px;
              margin-left: -15px;
              border-radius: 2px;
              background-color: rgba(51, 136, 255, 1);
            }
          }
        }
      }
      .mainPre-input-group-addon{
        background-color: #fff;
      }
      .searchWrap{
        width: 227px;
        flex-shrink: 0;
      }
      .iconWrap{
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
        padding-right: 5px;
        box-sizing: border-box;
        .erachIcon{
          width: 20px;
          height: 20px;
          margin-right: 16px;
        }
      }
    }
  }
}


@primary-color: #3388FF;@ant-prefix: mainPre;