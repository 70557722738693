.searchWrap{
    display: flex;
    align-items: center;
    .mainPre-select-selection-item{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
    }
}
.mainPre-empty{
    .mainPre-empty-image{
        width: 100%;
        height: 56px;
        text-align: center;
        img{
            width: 76px;
            height: 100%;
        }
    }
    .mainPre-empty-description{
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
    }
}
.ant-message {
    z-index: 1040!important;
}
.mainPre-spin-container{
    height: 200px !important;
    overflow-y: scroll;
}
@primary-color: #3388FF;@ant-prefix: mainPre;